import { client } from './client'
import { dataURLtoFile } from '../utils/helper'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import posthog from 'posthog-js'

const axios = client()
import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  UPCOMING_BOOKING_DETAILS,
  PAST_BOOKING_DETAILS,
  CUSTOMERPROFILEDETAILS,
  PAYMENTDETAILS,
  RELATEDCONTACTSLIST,
  EMERGENCYCONTACTSLIST,
  MEMBERSHIP,
  SAVEDCARDS,
  SETURLPREFIX,
  PAYMENTMODES,
  GET_PROFILE_IMAGE,
  PROFILE_IMAGE_ERROR,
  ALL_GIFT_CARDS,
  UPDATE_USER_PROFILE
} from 'Constants'
import { API_ROOT } from 'Config'
import { CONFIRM, GENERATE, VERIFY } from 'Constants/index'
import { store } from '../store.index'
import {
  BOOKING_TYPES,
  INSTALLMENTS,
  SAVED_PAYMENT_METHODS
} from '../constants'
import { getUrlParams } from '../components/Common'

let axiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('customerkey')
  }
}

export function loginUser(postData) {
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  delete axiosConfig.headers.Authorization

  let requestUrl = `${API_ROOT}/api/embed/auth/login/`
  let request = axios.post(requestUrl, postData, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        if (data && data.status == 'success') {
          let customerKey = data.data.access_token
          localStorage.setItem('customerkey', customerKey)
          localStorage.setItem('contact', JSON.stringify(data.data.contact))
          let url_prefix = localStorage.getItem('url_prefix')
          let clientSettings = localStorage.getItem('clientSettings')
          posthog.reset()
          clientSettings = JSON.parse(clientSettings)
          posthog.identify(data.data.contact.email, {
            email: data.data.contact.email,
            first_name: data.data.contact.first_name,
            last_name: data.data.contact.last_name,
            org: clientSettings?.name
          })
          if (clientSettings) {
            posthog.group('company', url_prefix, {
              business: clientSettings?.name
            })
          }
          //Clear out any previously stored user emails
          Sentry.configureScope(scope => {
            scope.setUser({
              email: null
            })
          })
          //Add user email to sentry scope
          Sentry.configureScope(scope => {
            scope.setUser({
              email: data.data.contact.email
            })
          })
          // Add user email to logrocket recordings
          LogRocket.identify(data.data.contact.email)
        }
        dispatch({ type: LOGIN, payload: data })
      })
      .catch(err => {
        dispatch({ type: LOGIN, payload: err.response?.data })
      })
  }
}

export function getError401() {
  const requestUrl = `${API_ROOT}/api/embed/401`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: '401ERROR', payload: {} })
      })
      .catch(err => {
        dispatch({ type: '401ERROR', payload: {} })
      })
  }
}
export function logoutUser() {
  localStorage.clear()
  //Remove any stored user emails in sentry scope
  posthog.reset()
  Sentry.configureScope(scope => {
    scope.setUser({
      email: null
    })
  })
  return dispatch => {
    dispatch({ type: LOGOUT, payload: null })
  }
}

export function setUrlPrefix(prefix) {
  localStorage.setItem('url_prefix', prefix)
  return dispatch => {
    dispatch({ type: SETURLPREFIX, payload: prefix })
  }
}

export function signupUser(Data) {
  const postData = Data
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  return async (dispatch, getState) => {
    let requestUrl = `${API_ROOT}/api/embed/auth/signup`
    let request = axios.post(requestUrl, postData, axiosConfig)
    request
      .then(({ data }) => {
        if (
          data &&
          data.status === 'success' &&
          !(data.data && 'exists' in data.data)
        ) {
          let customerKey = data.data.access_token
          localStorage.setItem('customerkey', customerKey)
          localStorage.setItem('contact', JSON.stringify(data.data.contact))
          let url_prefix = localStorage.getItem('url_prefix')
          let clientSettings = localStorage.getItem('clientSettings')
          clientSettings = JSON.parse(clientSettings)
          posthog.reset()
          posthog.identify(data.data.contact.email, {
            email: data.data.contact.email,
            first_name: data.data.contact.first_name,
            last_name: data.data.contact.last_name,
            org: clientSettings?.name
          })
          if (clientSettings) {
            posthog.group('company', url_prefix, {
              business: clientSettings?.name
            })
          }
          //Clear out any previously stored user emails
          Sentry.configureScope(scope => {
            scope.setUser({
              email: null
            })
          })
          //Add user email to sentry scope
          Sentry.configureScope(scope => {
            scope.setUser({
              email: data.data.contact.email
            })
          })
          // Add user email to logrocket recordings
          LogRocket.identify(data.data.contact.email)
        }
        dispatch({ type: SIGNUP, payload: data })
      })
      .catch(err => {
        dispatch({
          type: SIGNUP,
          payload: err.response?.data ?? 'Signup failed'
        })
      })
  }
}

export const getCustomSignupData = () => {
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  const requestUrl = `${API_ROOT}/api/embed/custom-signup`
  return axios.get(requestUrl, axiosConfig)
}

export function savePassword(postdata) {
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix,
    region_id: localStorage.getItem('region_id')
  }
  let requestUrl = `${API_ROOT}/api/embed/auth/password/reset`
  let request = axios.post(requestUrl, postdata, axiosConfig)
  return function(dispatch) {
    return request
      .then(({ data }) => {
        dispatch({ type: CONFIRM, payload: data })
      })
      .catch(err => {
        dispatch({ type: CONFIRM, payload: err.response?.data })
      })
  }
}

export function generateOtp(postdata) {
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix,
    region_id: localStorage.getItem('region_id')
  }
  let requestUrl = `${API_ROOT}/api/embed/auth/password/send/otp`
  let request = axios.post(requestUrl, postdata, axiosConfig)
  return function(dispatch) {
    return request
      .then(({ data }) => {
        dispatch({ type: GENERATE, payload: data })
        return data
      })
      .catch(err => {
        dispatch({ type: GENERATE, payload: err.response?.data })
      })
  }
}

export function verifyOtp(postdata) {
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let requestUrl = `${API_ROOT}/api/embed/auth/password/otp/verify`
  let request = axios.post(requestUrl, postdata, axiosConfig)
  return function(dispatch) {
    return request
      .then(({ data }) => {
        dispatch({ type: VERIFY, payload: data })
      })
      .catch(err => {
        dispatch({ type: VERIFY, payload: err.response?.data })
      })
  }
}

export function getUserProfile() {
  const customerKey = localStorage.getItem('customerkey')

  axiosConfig.headers.Authorization = 'Bearer ' + customerKey
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/profile`

  let request = null

  if (customerKey) request = axios.get(requestUrl, axiosConfig)

  return dispatch => {
    if (!request) return null

    request
      .then(({ data }) => {
        dispatch({ type: CUSTOMERPROFILEDETAILS, payload: data })
      })
      .catch(err => {
        dispatch({ type: CUSTOMERPROFILEDETAILS, payload: err })
      })
  }
}

export function clearCustomerProfile() {
  return dispatch => {
    dispatch({ type: CUSTOMERPROFILEDETAILS, payload: null })
  }
}

export function getAllBookings({
  pageNumber = 1,
  bookingType = BOOKING_TYPES.upcoming
}) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    page: pageNumber,
    type: bookingType
  }

  const requestUrl = `${API_ROOT}/api/embed/profile/bookings`
  const request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({
          type:
            bookingType === BOOKING_TYPES.past
              ? PAST_BOOKING_DETAILS
              : UPCOMING_BOOKING_DETAILS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type:
            bookingType === BOOKING_TYPES.past
              ? PAST_BOOKING_DETAILS
              : UPCOMING_BOOKING_DETAILS,
          payload: err
        })
      })
  }
}

export function getAllMemberships() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/profile/memberships`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: MEMBERSHIP, payload: data })
      })
      .catch(err => {
        dispatch({ type: MEMBERSHIP, payload: err })
      })
  }
}

export function getAllPayments() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/profile/payments`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: PAYMENTDETAILS, payload: data })
      })
      .catch(err => {
        dispatch({ type: PAYMENTDETAILS, payload: err })
      })
  }
}

export function getAllInstallments() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    status: 'pending'
  }

  let requestUrl = `${API_ROOT}/api/embed/payment_plan/installments`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: INSTALLMENTS, payload: data })
      })
      .catch(err => {
        dispatch({ type: INSTALLMENTS, payload: err })
      })
  }
}

export function addRelatedContact(postdata) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/profile/related`
  let request = axios.post(requestUrl, postdata, axiosConfig)
  return request
}

export function getRelatedContactsList() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let requestUrl = `${API_ROOT}/api/embed/profile/related`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({
          type: RELATEDCONTACTSLIST,
          payload: data.data.related_contacts
        })
      })
      .catch(err => {
        dispatch({ type: RELATEDCONTACTSLIST, payload: err })
      })
  }
}

export function getEmergencyContactsList() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }
  let requestUrl = `${API_ROOT}/api/embed/profile/emergency`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({
          type: EMERGENCYCONTACTSLIST,
          payload: data.data.emergency_contacts
        })
      })
      .catch(err => {
        dispatch({ type: EMERGENCYCONTACTSLIST, payload: err })
      })
  }
}

export function cancelBookings(postdata) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  let requestUrl = `${API_ROOT}/api/embed/events/${postdata.event_id}/bookings/${postdata.booking_id}`
  const axiosConfigObject = { headers: axiosConfig.headers }
  axiosConfigObject.params = {
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  return axios.delete(requestUrl, axiosConfigObject)
}

export function getPaymentMode() {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  let requestUrl = `${API_ROOT}/api/embed/gateways`
  const regionId = localStorage.getItem('region_id')

  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    center_id: localStorage.getItem('selected_center_id')
  }

  let request = null

  if (regionId) request = axios.get(requestUrl, axiosConfig)

  return dispatch => {
    if (request)
      request
        .then(({ data }) => {
          dispatch({ type: PAYMENTMODES, payload: data.data.payment_methods })
        })
        .catch(err => {
          dispatch({ type: PAYMENTMODES, payload: err.response?.data })
        })
  }
}

export function getAllCard(rid = false, cid = false) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')

  axiosConfig.params = {
    region_id: rid || localStorage.getItem('region_id'),
    center_id:
      cid ||
      localStorage.getItem('selected_center_id') ||
      localStorage.getItem('center_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  let requestUrl = `${API_ROOT}/api/embed/profile/sources`
  let request = axios.get(requestUrl, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: SAVEDCARDS, payload: data.data })
        dispatch({ type: SAVED_PAYMENT_METHODS, payload: data.data })
      })
      .catch(err => {
        dispatch({
          type: SAVEDCARDS,
          payload: err.response?.data ?? err
        })
        dispatch({
          type: SAVED_PAYMENT_METHODS,
          payload: err.response?.data ?? err
        })
      })
  }
}

export const setDefaultCard = id => {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')

  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix,
    center_id: localStorage.getItem('selected_center_id')
  }
  const requestUrl = `${API_ROOT}/api/embed/profile/sources/${id}`

  try {
    const request = axios.put(requestUrl, {}, axiosConfig)
    return request
  } catch (e) {
    console.log(e)
  }
}

export function deletePaymentMethod(id) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  let requestUrl = `${API_ROOT}/api/embed/profile/sources`
  const axiosConfigObject = {
    headers: axiosConfig.headers,
    data: { source: id },
    params: { url_prefix: store.getState().accountreducer.urlPrefix }
  }

  try {
    let request = axios.delete(requestUrl, axiosConfigObject)
    return request
  } catch (e) {
    console.log(e)
  }
}

export function getAllGiftCards(
  center_id = localStorage.getItem('selected_center_id')
) {
  const urlPrefix = getUrlParams()
  if (!localStorage.getItem('region_id') || !urlPrefix) {
    return dispatch => {
      dispatch({ type: ALL_GIFT_CARDS, payload: null })
    }
  }

  let planrequestUrl = `${API_ROOT}/api/webstore/profile/gift_cards?center_id=${center_id}&url_prefix=${localStorage.getItem(
    'url_prefix'
  )}&past=true`

  let request = axios.all([axios.get(planrequestUrl, axiosConfig)])

  return dispatch => {
    request
      .then(res => {
        dispatch({
          type: ALL_GIFT_CARDS,
          payload: res[0].data.data
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: ALL_GIFT_CARDS, payload: err })
      })
  }
}

export function linkProfile() {
  const store_state = store.getState()
  let center_id =
    localStorage.getItem('selected_center_id') ||
    localStorage.getItem('center_id')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store_state.accountreducer.urlPrefix,
    center_id: center_id
  }
  if (store_state.isLoggedReducer) {
    // Rehydrate authorization
    axiosConfig.headers.Authorization =
      'Bearer ' + localStorage.getItem('customerkey')
    let requestUrl = `${API_ROOT}/api/embed/profile/link`
    let request = axios.post(
      requestUrl,
      { check_center_id: center_id },
      axiosConfig
    )
    return request
  } else {
    return ''
  }
}

export function sendInvitation(data) {
  let center_id =
    localStorage.getItem('selected_center_id') ||
    localStorage.getItem('center_id')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    center_id: center_id
  }
  let requestUrl = `${API_ROOT}/api/embed/profile/invite`
  let request = axios.post(requestUrl, data, axiosConfig)
  return request
}

export function uploadProfileImage(imageUri) {
  return async dispatch => {
    axiosConfig.headers.Authorization =
      'Bearer ' + localStorage.getItem('customerkey')
    const imageItem = await dataURLtoFile(imageUri, 'profileImage.png')
    const fd = new FormData()
    fd.append('image', imageItem)
    fd.append('type', 'contacts/profile')

    axiosConfig.params = {
      region_id: localStorage.getItem('region_id'),
      url_prefix: store.getState().accountreducer.urlPrefix
    }
    const requestUrl = `${API_ROOT}/api/embed/profile/image`
    const request = axios.post(requestUrl, fd, axiosConfig)
    request
      .then(({ data }) => {
        dispatch({ type: GET_PROFILE_IMAGE, payload: data.url })
      })
      .catch(err => {
        dispatch({ type: PROFILE_IMAGE_ERROR, payload: err?.response?.data })
      })
  }
}

export function updateProfile(data) {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix,
    center_id: localStorage.getItem('selected_center_id')
  }
  const requestUrl = `${API_ROOT}/api/embed/profile`
  const request = axios.put(requestUrl, data, axiosConfig)
  return dispatch => {
    request
      .then(({ data }) => {
        dispatch({ type: UPDATE_USER_PROFILE, payload: data })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: UPDATE_USER_PROFILE, payload: err?.response?.data })
      })
  }
}

export function clearUpdateProfileStatus() {
  return dispatch => {
    dispatch({ type: UPDATE_USER_PROFILE, payload: null })
  }
}

export const getDocumentsByTrigger = data => {
  const token = localStorage.getItem('customerkey')
  axiosConfig.headers.Authorization = 'Bearer ' + token
  const requestUrl = `${API_ROOT}/api/embed/documents/trigger`
  axiosConfig.params = {
    region_id: localStorage.getItem('region_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  return axios.post(requestUrl, data, axiosConfig)
}

export const addRelatedContactToSharedMembership = (membershipId, postData) => {
  axiosConfig.headers.Authorization =
    'Bearer ' + localStorage.getItem('customerkey')
  axiosConfig.params = {
    center_id: localStorage.getItem('selected_center_id'),
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  const requestUrl = `${API_ROOT}/api/embed/profile/membership/${membershipId}/related`
  const request = axios.post(requestUrl, postData, axiosConfig)
  return request
}

export const getUserCommunicationPreferences = (email, token) => {
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix,
    email,
    token
  }

  const requestUrl = `${API_ROOT}/api/embed/marketing/preferences`
  return axios.get(requestUrl, axiosConfig)
}

export const unsubscribeFromEmails = postData => {
  axiosConfig.params = {
    url_prefix: store.getState().accountreducer.urlPrefix
  }

  const requestUrl = `${API_ROOT}/api/embed/marketing/preferences`
  return axios.post(requestUrl, postData, axiosConfig)
}
